import UsedVehiclesCard from '../auto/components/Cards/UsedVehiclesCitroen';
import FcaCard from '../auto/components/Cards/Fca';
import OfferDDMListApp from '../auto/components/Listings/OffersDDM/ListApp';
import OfferDDMFilter from '../auto/components/Listings/OffersDDM/Filter/SimpleFilter';
import OfferDDMResultSearchSimple from '../auto/components/Listings/OffersDDM/Filter/Result/ResultSearchSimple';
import OfferDDMConversionFormNoResult from '../auto/components/Listings/OffersDDM/Filter/Result/ConversionFormNoResult';
import VersionComparison from '../auto/components/VersionComparison/VersionComparison';
import WhatsappChatConversionForm from '../auto/components/Whatsapp/ChatConversionForm';
import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;
  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    UsedVehiclesCard,
    FcaCard,
    OfferDDMListApp,
    OfferDDMFilter,
    OfferDDMResultSearchSimple,
    OfferDDMConversionFormNoResult,
    WhatsappChatConversionForm,
    VersionComparison,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
